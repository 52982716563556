import {
  BooleanField,
  List,
  Datagrid,
  TextField,
  CreateButton,
  ExportButton,
  TopToolbar,
  FunctionField as AorFunctionField,
  ShowButton,
} from 'react-admin';
import React from 'react';
import FunctionField from 'utility/FunctionField';
import { offerStatuses, offerPublishedStatuses } from 'entoro-shared';
import EditButton from 'utility/EditButton';

const ListActions = () => {
  let isBanker =
    JSON.parse(localStorage.getItem('issuer'))?.issuerType ===
    'ISSUER_MODERATOR_ADMIN';
  return (
    <TopToolbar>
      {!isBanker && <CreateButton />}
      <ExportButton />
    </TopToolbar>
  );
};
const container = {
  maxWidth: '18em',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '15px',
};

export const OfferList = (props) => {
  // console.log(props);
  let isBanker =
    JSON.parse(localStorage.getItem('issuer'))?.issuerType ===
    'ISSUER_MODERATOR_ADMIN';
  return (
    <List
      {...props}
      actions={<ListActions />}
      title='Offers'
      perPage={10}
      sort={{ field: 'order', order: 'ASC' }}
    >
      <Datagrid>
        <TextField source='name' />

        <TextField
          // style={container}
          source='status'
          label='Status'
          render={offerStatuses.getLabel}
        />
        <BooleanField style={container} source='approved' label='Approved' />

        <FunctionField
          // style={container}
          source='visibility'
          label='Visibility'
          render={offerPublishedStatuses.getLabel}
        />

        <TextField
          // style={container}
          source='issuerOfferApprovalStatus'
          label='Tenant Approval'
        />
        <TextField
          // style={container}
          source='issuerCompany.name'
          label='Company'
          sortable={false}
        />

        {/* <FunctionField
          // style={container}
          source='typeOfOffer'
          render={offerTypes.getLabel}
          label='Type Of Offer'
        /> */}

        {/* <FormDataConsumer>
          {({ formData, ...rest }) =>
            !formData.requestApprovalIssuer && (
              <EditButton {...props} label='Edit' basePath='/offers' />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.requestApprovalIssuer && (
              <ShowButton label='Edit' basePath='/offers' />
            )
          }
        </FormDataConsumer> */}
        <AorFunctionField
          render={
            (record) => (
              // record.requestApprovalIssuer || isBanker ? (
              //   <ShowButton
              //     label='Show'
              //     {...props}
              //     basePath='/offers'
              //     record={record}
              //   />
              // ) : (
              <EditButton
                {...props}
                label='Edit'
                basePath={'/offers'}
                record={record}
              />
            )
            // )
          }
        />
      </Datagrid>
    </List>
  );
};
